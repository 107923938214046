<template>
  <div>
    <b-card title="" header-tag="header">
      <template v-slot:header>
        <div class="card-title m-0">
          <h3 class="card-label m-0">{{ title }}</h3>
        </div>
        <div class="card-toolbar"></div>
      </template>

      <form class="form" novalidate="novalidate" id="product_basic">
        <b-form-group label-for="input-1">
          <label>Status <sup class="text-danger font-weight-boldest">*</sup></label>
          <b-form-select name="status" v-model="form.status" :options="statusList">
          </b-form-select>
        </b-form-group>

        <b-form-group label-for="input-1">
          <label>Amount </label>
          <b-form-input
            v-model="form.refund_amount"
            type="number"
            name="amount"
            class="form-control"
          ></b-form-input>
        </b-form-group>

        <b-form-group label-for="input-1">
          <label>Refund Message </label>
          <b-form-input
            v-model="form.refund_message"
            type="text"
            name="msg"
            class="form-control"
          ></b-form-input>
        </b-form-group>

        <b-form-group label-for="input-1">
          <label>Remark </label>
          <b-form-input
            v-model="form.remarks"
            type="text"
            name="remark"
            class="form-control"
          ></b-form-input>
        </b-form-group>

        <b-row>
          <b-col class="text-right">
            <button
              ref="kt_login_signin_submit"
              class="btn btn-sm btn-outline-primary font-size-h6"
            >
              <i class="las la-save"></i> Save
            </button>
          </b-col>
        </b-row>
      </form>
    </b-card>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Swal from "sweetalert2";
// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import KTUtil from "@/assets/js/components/util";
//api services
import CancellationService from "@/core/services/api/cancel";
export default {
  components: {},
  data() {
    return {
      title: "Cancel Status Change ",
      statusList: [
        {
          text: "Refunded",
          value: "refunded",
        },
        {
          text: "Reject",
          value: "rejected",
        },
      ],
      form: {
        Cancellation_id: this.$route.params.id ? parseInt(this.$route.params.id) : null,
        status: "",
        refund_amount: 0,
        refund_message: "",
        remarks: "",
      },
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Dashboard",
        route: "/dashboard",
      },
      {
        title: "Cancellation",
        route: "/cancellation",
      },
      {
        title: "Cancellation : Status Change",
      },
    ]);
    const signin_form = KTUtil.getById("product_basic");
    this.fv = formValidation(signin_form, {
      fields: {
        status: {
          validators: {
            notEmpty: {
              message: "Status is required",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });
    this.fv.on("core.form.valid", () => {
      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right", "disabled");
      var vm = this;

      this.updateStatus(function (response) {
        vm.response(response, submitButton);
      });
    });
    if (this.form.Cancellation_id != null) {
      this.getCancellationRequestById();
    }
  },
  methods: {
    async getCancellationRequestById() {
      var response = await CancellationService.getCancellationRequestById(
        this.form.Cancellation_id
      );
      if (response != undefined) {
        this.form.refund_amount = response.refund_amount;
        this.form.refund_message = response.refund_message;
        this.form.remarks = response.remarks;
      }
    },
    response(response, submitButton) {
      var vm = this;
      if (response) {
        let timerInterval;
        Swal.fire({
          title: response.message,
          timer: 500,
          icon: "success",
          timerProgressBar: true,
          showConfirmButton: false,
          showCancelButton: false,
          didOpen: () => {
            Swal.showLoading();
            const b = Swal.getHtmlContainer().querySelector("b");
            timerInterval = setInterval(() => {
              b.textContent = Swal.getTimerLeft();
            }, 100);
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        }).then((result) => {
          vm.$router.push({ name: "cancellations" });
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
          }
        });
      }
      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right",
        "disabled"
      );
    },
    updateStatus(callback) {
      CancellationService.updateStatus(this.form).then(function (response) {
        callback(response);
      });
    },
  },
};
</script>

<style></style>
