import ApiService from "@/core/services/api.service.js";

const cancelService = {
    getNewCancellationList(param) {
        return  new Promise((resolve, reject) => {
          
          var paramStr = ''
          if(param.page != undefined){
            paramStr = '?page='+(param.page+1)
          }else{
            paramStr = '?'
          }
          for (const key in param.filters) {
            if(param.filters[key] != null)
              paramStr += '&' + key + '=' + param.filters[key]
          }
          
          ApiService.get("api/admin/order/cancel/request/list"+paramStr,param)
            .then(function(response) {
              if (response.data) {
                resolve(response.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              resolve("");
              reject(error)
            });
        });
    },

    getCancellationList(param) {
      return  new Promise((resolve, reject) => {
        
        var paramStr = ''
        if(param.page != undefined){
          paramStr = '?page='+(param.page+1)
        }else{
          paramStr = '?'
        }
        for (const key in param.filters) {
          if(param.filters[key] != null)
            paramStr += '&' + key + '=' + param.filters[key]
        }
        
        ApiService.get("api/admin/order/cancel/list"+paramStr,param)
          .then(function(response) {
            if (response.data) {
              resolve(response.data);
            } else {
              resolve("");
            }
          })
          .catch(function(error) {
            resolve("");
            reject(error)
          });
      });
  },

    getCancellationRequestById(id) {
      return  new Promise((resolve, reject) => {
        ApiService.get("api/admin/order/cancel/request/detail/"+id)
          .then(function(response) {
            if (response.data.data) {
              resolve(response.data.data);
            } else {
              resolve("");
            }
          })
          .catch(function(error) {
            reject(error)
          });
      });
  },
  updateStatus(data) {
    return  new Promise((resolve, reject) => {
      ApiService.post("api/admin/order/cancel/request/update/"+data.Cancellation_id,data)
        .then(function(response) {
          debugger
          if (response.data) {
            resolve(response.data);
          } else {
            resolve("");
          }
        })
        .catch(function(error) {
          reject(error)
        });
    });
},
}

export default cancelService;